<template>
  <div>
    <report-table />
  </div>
</template>

<script>
  import { mapActions, mapState, mapMutations } from 'vuex'
  import ReportTable from '../components/business-reports/ReportTable.vue'
  export default {
    name: 'BusinessReports',
    components: {
      ReportTable,
    },
    computed: {
      ...mapState(['user', 'actionsParentBusiness', 'actionWarehouse', 'warehouses']),
    },

    watch: {
      async warehouses (v) {
        // // // // console.log('almacen de la tienda', v[0])
        this.setActionWarehouse(v[0].warehouseId)
        await this.fetchStoreInventory({ id: v[0].warehouseId })
        // await this.getBusinessChartData({ warehouseId: v[0].warehouseId, businessId: this.actionsBusiness })
      },
    },

    async created () {
      this.setActions(Number(this.$route.params.id))
      this.setEnvironment('business')
      await this.getWarehousesByParentId({ id: this.$route.params.id })

      await this.fetchWarehouseConcessionNoApprove({ id: this.actionWarehouse, noApprove: true })
      await this.fetchProducts({ id: this.user.businessId || this.actionsParentBusiness })
      await this.getWarehousesByParentId({ id: this.user.businessId || this.actionsParentBusiness })
      await this.getUsersBySucursalId({ id: this.$route.params.id })
      await this.getStoreWarehouses()
      await this.getParentChilds(this.user.businessId || this.actionsParentBusiness)
      await this.getUsers()
      await this.fetchAllClient()
      await this.fetchProviders()
    },

    methods: {
      ...mapActions([
        'fetchProviders',
        'fetchWarehouseConcessionNoApprove',
        'fetchProducts',
        'getWarehousesByParentId',
        'getUsersBySucursalId',
        'getStoreWarehouses',
        'getUsers',
        'fetchAllClient',
        'getParentChilds',
        'getWarehousesByParentId',
        'fetchStoreInventory',
        'getBusinessChartData',
      ]),
      ...mapMutations({
        setEnvironment: 'SET_ACTIONS_ENVIRONMENT',
        setActions: 'SET_ACTIONS_BUSINESS',
        setActionWarehouse: 'SET_ACTIONS_WAREHOUSE',
      }),
    },
  }
</script>
