<template>
  <v-form
    v-model="isValidData"
    @submit="submit"
  >
    <v-card-text>
      <v-container>
        <!-- {{ disabledSave }} -->
        <v-sheet elevation="0">
          <v-row class="mx-4">
            <!-- <v-col
              cols="12"
              align-self="start"
            >
              <p class="font-weight-thin text-right">
                Valor total de la entrada
              </p>
              <p
                class="font-weight-black text-right green--text"
                :style="{
                  fontSize: '30px'
                }"
              >
                + 1000$
              </p>
            </v-col> -->

            <!-- <pre>{{ productSelected }}</pre> -->
            <!-- {{ total }} -->
            <!-- <pre>{{ JSON.stringify(getWarehouses, null, 2) }}</pre> -->
            <v-col
              cols="12"
            >
              <span
                v-if="alert"
                class="red--text"
              >
                La transferencia no puede ser mayor a disponibilidad del producto
              </span>
            </v-col>
            <v-col
              cols="12"
            >
              <v-autocomplete
                v-model="data.relatedWarehouses"
                label="Almacen a transferir"
                :loading="fetchingWarehouses"
                :disabled="fetchingWarehouses"
                :items="getWarehouses"
                item-text="name"
                no-data-text="No hay coincidencia"
                return-object
                outlined
              />
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-autocomplete
                v-model="productSelected"
                label="Productos"
                :loading="fetchingProducts"
                :disabled="fetchingProducts"
                :items="getProducts"
                item-text="description"
                no-data-text="No hay coincidencia"
                return-object
                outlined
              />
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-text-field
                v-model="cant"
                type="number"
                min="1"
                :max="getCantbySelectProduct"
                :rules="requiredFieldRules('Cantidad es requerido')"
                outlined
                label="Cantidad"
                required
              />
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                class="mx-2"
                fab
                dark
                color="secondary"
                @click="addProductList"
              >
                <v-icon dark>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-data-table
                class="elevation-1"
                no-data-text="No hay productos cargados"
                :headers="headers"
                :items="data.products"
                :items-per-page="5"
                :footer-props="{
                  'items-per-page-text': 'Filas por página',
                  pageText: '{0}-{1} de {2}'
                }"
              >
                <template v-slot:[`item.actions`]="{ item: product }">
                  <v-btn
                    color="red"
                    class="transparent-button-icon"
                    icon
                    @click="deleteProductList(product.id)"
                  >
                    <v-icon>
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="data.description"
                outlined
                name="input-7-4"
                label="Observaciones"
                no-resize
              />
            </v-col>
          </v-row>
        </v-sheet>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="red"
        text
        type="button"
        :disabled="fetchingProducts"
        @click="$emit('close')"
      >
        Cerrar
      </v-btn>
      <v-btn
        type="submit"
        color="success"
        :disabled="disabledSave"
        :loading="fetchingProducts"
      >
        Agregar
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { TYPE_TRANSACTION } from '../../../../const'

  export default {
    name: 'AddConcessionRequestForm',
    data () {
      return {
        isValidData: false,
        step: 1,
        salesUnits: ['UNIDAD', 'KG', 'M', 'LB'],
        headers: [
          {
            text: 'Nombre del producto',
            value: 'description',
          },
          {
            text: 'Cantidad',
            value: 'cant',
          },
          {
            text: 'Acciones',
            value: 'actions',
          },
        ],
        alert: false,
        productSelected: null,
        cant: 1,
        data: {
          relatedWarehouses: null,
          description: '',
          products: [],
        },
      }
    },
    computed: {
      ...mapState([
        'user',
        'actionsParentBusiness',
        'fetchingProducts',
        'products',
        'warehouses',
        'actionWarehouse',
        'fetchingWarehouses',
        'warehousesInventory',
        'fechingWarehouseInventory',
        'actionEnvironmentData',
      ]),

      getProducts () {
        // // // // // // // // // // // // // // // // // // // // // // // // console.log('movement store products', this.products)
        let res = this.products.filter(item => item.status)
        res = res.map(item => {
          item.productId = item.productId ? item.productId : item.id
          delete item.id

          return item
        })
        return res
      },

      getCantbySelectProduct () {
        // // // // // // // // // // // // // // // // // // // // // // // // console.log('productId', this.productSelected?.productId)
        let res = this.warehousesInventory.filter(item => item?.productId === this.productSelected?.productId)
        // // // // // // // // // // // // // // // // // // // // // // // // console.log('res', res)
        res = res[0]?.cantidad

        const chargedProduct = this.data.products.filter(item => item.productId === this.productSelected?.productId)
        // // // // // // // // // // // // // // // // // // // // // // // // console.log('cP', chargedProduct)
        return res - (chargedProduct[0]?.cant || 0)
      },

      getWarehouses () {
        return this.warehouses.filter(item => item.status && item.id !== this.actionWarehouse)
      },

      businessId () {
        return (this.user.businessId || this.actionsParentBusiness) || null
      },

      total () {
        let total = 0
        this.data.products.forEach((item, i) => {
          // // // // // // // // // // // // // // // // // // // // // // // // console.log(i, item)
          total = total + (item.bigWholesalePrices * item.cant)
        })
        return total
      },

      disabledSave () {
        let res = false
        // for (const [key] of Object.entries(this.data)) {
        //   res = this.data[key] === false || this.data[key] === null
        // }
        if (this.data.products.length < 1) {
          res = true
        }
        return res
      },
    },

    methods: {
      ...mapActions([
        'fetchWarehouseMovements',
        'createWarehouseMovements',
      ]),

      addProductList () {
        // if (this.cant <= this.getCantbySelectProduct) {
        if (this.productSelected && this.cant) {
          // // // // // // // // // // // // // // // // // // // // // // // // console.log('producto sleccionado', this.productSelected)
          if (this.data.products.filter(item => item.productId === this.productSelected.productId).length >= 1) {
            this.data.products = this.data.products.map(item => item.productId === this.productSelected.productId ? ({
              ...item,
              cant: Number(item.cant) + Number(this.cant),
            }) : item)
          } else {
            this.data.products.push({ ...this.productSelected, cant: this.cant })
          }
        }
        this.alert = false
        this.productSelected = null
        this.cant = 1
        // } else {
        //   this.alert = true
        // }
      },

      deleteProductList (id) {
        this.data.products = this.data.products.filter(item => item.id !== id)
      },

      requiredFieldRules (text) {
        return [
          v => !!v || text,
        ]
      },

      assingMax () {
        this.cant = this.getCantbySelectProduct
      },

      resetData () {
        // for (const [key] of Object.entries(this.data)) {
        //   this.data[key] = '' // Making all keys of the object ''
        // }

        this.data = {
          relatedWarehouses: null,
          description: '',
          products: [],
        }
      },
      async afterCreate () {
        this.$emit('close')
        this.resetData()
        await this.fetchWarehouseMovements({ id: this.actionWarehouse })
      },
      async submit (e) {
        e.preventDefault()
        // const businessId = this.businessId
        const total = this.total
        if (this.isValidData && this.businessId !== null && this.disabledSave === false) {
          // const submit = {

          // }
          // // // // // // // // // // // // // // // // // // // // // // // // // console.log('data', {
          //   ...this.data,
          //   total: total,
          // })

          const submit = {
            ...this.data,
            typeTransactionId: TYPE_TRANSACTION.movimiento,
            userId: this.user.id,
            warehouseId: this.actionWarehouse,
            relatedWarehouseId: this.data.relatedWarehouses.warehouseId,
            total: total,
            code: this.actionEnvironmentData.code,
          }

          // // // // // // // // // // // // // // // // // // // // // // // // console.log('data', submit)

          await this.createWarehouseMovements(submit)

          this.afterCreate()

          // const submit = {
          //   ...this.data,
          //   businessId,
          // }
          // // // // // // // // // // // // // // // // // // // // // // // // // console.log('sub', submit)
          // await this.createProducts(submit)
          // this.afterCreate()
        }
      },
    },
  }
</script>

<style scoped>
  .transparent-button-icon {
    background-color: transparent !important;
  }
</style>
